import {toRubles} from "@/utils/money";

export const PAID = [
    {
        key: 'order_id',
        label: 'Номер заказа',
    },
    {
        key: 'ticket_id',
        label: 'Номер билета',
    },
    {
        key: 'passenger_fio',
        label: 'ФИО Пассажира',
    },
    {
        key: 'created_at',
        label: 'Дата и время (IRK)',
    },
    {
        key: 'carrier_name',
        label: 'Перевозчик'
    },
    {
        key: 'route_name',
        label: 'Маршрут'
    },
    {
        key: 'start_time',
        label: 'Рейс'
    },
    {
        key: 'status',
        label: 'Статус'
    },
    {
        key: 'payment_type',
        label: 'Метод оплаты'
    },
    {
        key: 'cashier',
        label: 'Кассир'
    },
    {
        key: 'sum_amount',
        label: 'Сумма',
        formatter: value => toRubles(value)
    },
    {
        key: 'ticket_amount',
        label: 'Билеты',
        formatter: value => toRubles(value)
    },
    {
        key: 'baggage',
        label: 'Багаж',
        formatter: value => toRubles(value)
    },
    {
        key: 'service_fee',
        label: 'СС',
        formatter: value => toRubles(value)
    },
    {
        key: 'cost_cash_box_fee',
        label: 'КС',
        formatter: value => toRubles(value)
    },
    {
        key: 'paid_sum',
        label: 'Оплачено',
        formatter: value => toRubles(value)
    }
]

export const REFUNDED = [
    ...PAID,
    {
        key: 'refund_amount',
        label: 'Возвращено',
        formatter: value => toRubles(value)
    },
    {
        key: 'deducted',
        label: 'Удержано',
        formatter: value => toRubles(value)
    },
    {
        key: 'refund_type',
        label: 'Причина'
    },
];