<template>
  <div>
    <b-col cols="12">
      <h2>Детальная статистика по продажам и возвратам</h2>
    </b-col>
    <b-overlay :show="loading" class="mb-4">
      <b-card class="mb-4 mt-4">
        <b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Начиная с даты">
                <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" v-model="filters.dateFrom" reset-button/>
              </b-form-group>
              <b-form-group label="Кассиры">
                <b-dropdown :text="cashiersText ? cashiersText : 'Выберите кассиров'" variant="outline-secondary" split-class="">
                  <div class="dropdown-item bg-white text-body">
                    <b-form-checkbox-group stacked v-model="filters.checked.cashiers" :options="mappedCashiers"/>
                  </div>
                </b-dropdown>
              </b-form-group>
              <b-form-group label="Перевозчики">
                <b-dropdown :text="carriersText ? carriersText : 'Выберите перевозчиков'" variant="outline-secondary" split-class="">
                  <div class="dropdown-item bg-white text-body">
                    <b-form-checkbox-group stacked v-model="filters.checked.carriers" :options="mappedCarriers"/>
                  </div>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Заканчивая датой">
                <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" v-model="filters.dateTo" reset-button/>
              </b-form-group>
              <b-form-group label="Методы оплаты">
                <b-dropdown :text="paymentTypesText ? paymentTypesText : 'Выберите методы оплаты'" variant="outline-secondary" split-class="">
                  <div class="dropdown-item bg-white text-body">
                    <b-form-checkbox-group stacked v-model="filters.checked.paymentTypes" :options="paymentTypes"/>
                  </div>
                </b-dropdown>
              </b-form-group>
              <b-form-group label="Маршруты">
                <b-dropdown :text="routesText ? routesText : 'Выберите маршруты'" variant="outline-secondary" split-class="">
                  <div class="dropdown-item bg-white text-body">
                    <b-form-checkbox-group stacked v-model="filters.checked.routes" :options="mappedRoutes"/>
                  </div>
                </b-dropdown>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <b-form-checkbox v-model="filters.personalInfo">Отобразить персональные данные пассажира</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <div class="d-flex">
          <b-button variant="primary" @click="generateReport">Применить</b-button>
        </div>
      </b-card>
    </b-overlay>
    <div>
      <b-card class="mb-4 mt-4">
        <b-tabs content-class="mt-3">
          <b-tab title="Продажи" active>
            <b-table :items="data.sales.data" :fields="paidFields" responsive>
              <template #cell(passenger_fio)="data">
                {{data.item.last_name}} {{data.item.name}}  {{data.item.middle_name}}
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Возвраты">
            <b-table :items="data.refunds.data" :fields="refundedFields" responsive>
              <template #cell(passenger_fio)="data">
                {{data.item.last_name}} {{data.item.name}}  {{data.item.middle_name}}
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import ReportsApi from "@/api/reports.api";
import {toRubles} from "@/utils/money";
import {mapState} from "vuex";
import {PAID, REFUNDED} from "@/pages/Reports/fields/DetailedStatisticsBySalesAndRefunds";

const createTextFilter = (mappedEntities, checkedFilters) => {
    const keyableEntities = Object.fromEntries(mappedEntities.map(entity => [entity.value, '"' + entity.text + '"']))
    return checkedFilters.map(entityValue => keyableEntities[entityValue]).join(', ')
}

export default {
  name: "DetailedStatisticsBySalesAndRefunds",
  data() {
    return {
      loading: false,
      filters: {
        dateFrom: null,
        dateTo: null,
        timeType: 'payment_type',
        personalInfo: false,
        cashiers: {},
        checked: {
          cashiers: [],
          carriers: [],
          paymentTypes: [],
          routes: []
        }
      },
      loadedFilters: {
        timeType: 'payment_type',
        type: 'carriers'
      },
      paidFields: PAID,
      refundedFields: REFUNDED,
      data: {
        sales: [],
        refunds: []
      },
      paymentTypes: [
        {
          text: 'Наличные',
          value: 'cash'
        },
        {
          text: 'Безналичные',
          value: 'cashless'
        },
      ]
    }
  },
  methods: {
    toRubles(amount) {
      return toRubles(amount);
    },
    generateReport() {
      if (!this.filters.dateFrom || !this.filters.dateTo) {
        alert('Даты опязательны для заполнения');
        return;
      }

      this.loading = true

      ReportsApi.detailedSalesAndRefunds({
        dateFrom: this.filters.dateFrom,
        dateTo: this.filters.dateTo,
        paymentTypes: this.filters.checked.paymentTypes,
        cashiers: this.filters.checked.cashiers,
        routes: this.filters.checked.routes,
        carriers: this.filters.checked.carriers,
        personalInfo: this.filters.personalInfo
      }).then(response => {
        this.data = response.data;
        console.log(response.data)
      }).finally(() => {
        this.loading = false
      });
    }
  },
  computed: {
    cashiersText() {
      return createTextFilter(this.mappedCashiers, this.filters.checked.cashiers)
    },
    mappedCashiers() {
      return this.users.map(user => {
        return {
          text: user.email,
          value: user.id
        }
      });
    },
    carriersText() {
      return createTextFilter(this.mappedCarriers, this.filters.checked.carriers)
    },
    mappedCarriers() {
      return this.carriers.map(carrier => {
        return {
          text: carrier.carrierName,
          value: carrier.id
        }
      });
    },
    routesText() {
      return createTextFilter(this.mappedRoutes, this.filters.checked.routes)
    },
    mappedRoutes() {
      return this.routes.map(route => {
        return {
          text: route.name + ' - ' + route.number,
          value: route.id
        }
      });
    },
    paymentTypesText() {
      return createTextFilter(this.paymentTypes, this.filters.checked.paymentTypes)
    },
    ...mapState({
      users: state => state.Users.items,
      carriers: state => state.Carriers.items,
      routes: state => state.Routes.items
    })
  }
}
</script>

<style scoped>

</style>